import {useRedirectToCustomLogin} from '@cohort/wallet/hooks/redirectToCustomLogin';
import {
  getChallengeLoginRoute,
  getMerchantSpaceLoginRoute,
  getStoreLoginRoute,
} from '@cohort/wallet/lib/Pages';
import {useCallback} from 'react';
import {useLocation, useMatch, useNavigate} from 'react-router-dom';

export default function useHandleLoginNavigation(): (cohortRedirect: string) => void {
  const navigate = useNavigate();
  const location = useLocation();
  const isStore = useMatch('/store/*');
  const isChallenge = useMatch('/challenges/*');
  const {redirectCustomLoginEnabled, redirectToCustomLogin} = useRedirectToCustomLogin();

  const navigateToLogin = useCallback((cohortRedirect: string) => {
    if (redirectCustomLoginEnabled) {
      redirectToCustomLogin(cohortRedirect);
      return;
    }

    if (!isStore && !isChallenge) {
      return navigate({
        pathname: getMerchantSpaceLoginRoute(),
        search: location.search,
      });
    }
    const campaignSlugRegex = /^\/(store|challenges)\/([a-zA-Z0-9-_]+)/u;
    const campaignSlugRegexMatch = location.pathname.match(campaignSlugRegex);
    const campaignSlug = campaignSlugRegexMatch?.[2];
    const loginRoute = !campaignSlug
      ? getMerchantSpaceLoginRoute()
      : isStore
        ? getStoreLoginRoute(campaignSlug)
        : getChallengeLoginRoute(campaignSlug);

    return navigate({
      pathname: loginRoute,
      search: location.search,
    });
  }, []);

  return navigateToLogin;
}
