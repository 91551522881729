import type {TikTokKeywordInDescriptionTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/keywordInDescription';
import {TikTokKeywordInDescriptionTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/keywordInDescription';
import TikTokKeywordInDescriptionTriggerIntegrationDetailsComponent from '@cohort/wallet/apps/tiktok/triggers/keywordInDescription/DetailsComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const TikTokKeywordInDescriptionTriggerIntegration: TriggerIntegration<TikTokKeywordInDescriptionTriggerStruct> =
  {
    spec: TikTokKeywordInDescriptionTriggerSpec,
    detailsComponent: TikTokKeywordInDescriptionTriggerIntegrationDetailsComponent,
    actionCtaComponent: null,
    usageComponent: null,
  };
